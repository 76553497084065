import React from 'react';

export default (props)=>(
    
    <tr>
    <td width="10%">{props.session}</td>
    <td width="80%">
<div>{props.speaker ? <strong>Topic    :</strong> : ""} {props.topic}</div>
<div>{props.speaker ? <strong>Speaker    :</strong> : ""} {props.speaker}</div>
    </td>
<td width="10%" style={{whiteSpace:'pre'}}>{props.time}</td>

  
  </tr>
)