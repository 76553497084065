import React from "react";
import { Table } from "reactstrap";
import Speakers from "./Speakers";

function EventDetails() {
  return (
    <div className="container">
      <h1 className="my-4">Event Details</h1>
      <hr className="my-4 " color="primary" />
      <Table hover responsive>
        <tbody>
          <tr>
            <th scope="row">Type of event</th>
            <td>Hybrid Event</td>
          </tr>
          <tr>
            <th scope="row">Name of event</th>
            <td>
              IEEE SPS Seasonal School on Recent advances in Artificial Intelligence for Signal Processing
            </td>
          </tr>
          <tr>
            <th scope="row">Dates</th>
            <td>28th December - 31st December 2021</td>
          </tr>
          <tr>
            <th scope="row">Key Events</th>
            <td style={{color: '#069',fontWeight: 700, fontSize: '1.2em'}}>
            Technical Sessions

              <br />
              Poster Presentation
              <br />
              Panel Discussion
              <br />
            </td>
          </tr>
        </tbody>
      </Table>
      <h4 className="my-4 font-weight-bold">Technical Description</h4>
      <p>Artificial Intelligence(AI) makes computers automatically learn and respond without
being explicitly programmed. Speech processing, image processing, weather
predictions and other major signal processing applications are currently using
machine learning techniques to automate the mechanism of processing of signals.
Machine learning techniques share major part in the implementation of AI systems
in signal processing especially for healthcare. These technologies are showing their
potential in getting solutions to many bio medical applications like predicting cancer,
epileptic seizure, etc. Machine Learning techniques are more suitable because of the
underlying mathematics is fairly straightforward regardless of the algorithm used.
The complexity and the mystery of algorithms lie in the amount of data process to
get the interesting results. Hence, there is a great need of knowing how AI is used for
these purposes accompanied with signal processing. The term &quot;deep learning&quot; refers
to new methods and strategies for generating the deep hierarchies of non-linear
features by overcoming the problems with vanishing gradients, allowing us to train
architectures with dozens of layers of non-linear hierarchical features. Deep learning
is associated with learning not only deep non-linear hierarchical features, but also
learning to detect very long non-linear time dependencies in sequential data.
This event presents the advance signal processing concepts and tools needed to apply
Artificial intelligence in distributed areas. Acquire the knowledge and hands on
experience of latest enhancements in deep learning techniques. Latest techniques for
capturing, processing, manipulating, learning and classifying signals will be
discussed. Participants will have the familiarity of system design and development,
procedures, architectures, and applications for signal processing and Artificial
intelligence.
      </p>
      <h4 className="my-4 font-weight-bold">Topics to be Covered</h4>
      <p>
            • Introduction to fundamentals of Artificial intelligence
      <br />• Overview of various machine learning algorithms
      <br />• Developments in signal processing during last decade 
      <br />• Artificial intelligence for speech processing applications
      <br />• Audio classification using machine learning
      <br />• Deep Learning Concepts and Applications
      <br />• Pattern Classification using Adaptive Neural Network
      <br />• Deep Learning for Medical Image analysis in disease classification
      <br />• Signal processing and Artificial intelligence for wireless sensor networks
      <br />• Optimization of machine learning techniques for signal processing applications.
      <br />• Improvements and amendments in Artificial intelligence for digital signal processing
      <br />• Challenges and open discussion for Artificial intelligence in signal processing
      <br />• Hands on Sessions on Python for Machine Learning Algorithms
      </p>
      
      <h4 className="my-4 font-weight-bold">
        Organizations Associated
      </h4>
      <p>
        We are inviting the resource persons from the following organizations:
        <br />• IISc Banglore  <br />
              • IIT Hyderabad  <br />
              • IIT Chennai  <br />
              • IIT BHU  <br />
              • IIIT Hyderabad  <br />
              • NIT Patna  <br />
              • JNTU Hyderabad  <br />
              • Osmania University, Hyderabad  <br />
              • Tata Consultancy Services Limited  <br />
              • Blaze  Automation  <br />
              • Niramai Health Analytix Private Limited  <br />
              • Sapien Biosciences Pvt ltd  <br />
              • IFIA  <br />
              • BioAxis DNA Research Centre (P) Ltd  <br />
              • AIC-CCMB  <br />
              • Salcit Technologies  <br />
                    </p>
      
      {/* <Speakers classes="page-section pt-1 pb-1"/> */}
      {/* <h4 className="my-4 font-weight-bold">
        The estimated number of SPS Members this event will impact: 50
      </h4>
      <h4 className="my-4 font-weight-bold">
        Special activities or initiatives that enhance the impact of the event
      </h4>
      <p className="mb-4">
        In addition to the lecture sessions, we have planned <br />
        a. A Panel Discussion will be planned on current trends in Biomedical
        and Healthcare applications and its impact on human lives.
        <br />
        b. Live demonstrations on Signal processing applications by industry
        experts.
        <br />
        c. An exhibit of Hardware projects and Poster Presentation.
        <br className="my-4" />
      </p> */}
    </div>
  );
}

export default EventDetails;
