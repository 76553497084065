import React from "react";
import TeamMember from './TeamMember';
import Committee from './AdvisoryCommittee';
import Chairs from './Chairs';
import Members from './Members';
import Students from './Students';
import sessionsChair from '../assets/Team/B.V.Kiranmai.jpg';
import transportChair from '../assets/Team/Dr.Padmasree.jpg';
import generalChair from '../assets/Team/Dr.Y.Padma Sai.jpg';
import mediaChair from '../assets/Team/DrCKiran.jpg';
import technicalChair from '../assets/Team/DrKishore.jpg';
import financeChair1 from '../assets/Team/Manjulasree.jpg';
import financeChair2 from '../assets/Team/Priyanka.jpg';
import organizingChair from '../assets/Team/RajendraPrasad.jpg';
import websiteChair from '../assets/Team/rameshchandra.jpg';
function Team() {
  return (
    <div>
      <Committee />
      <Chairs />
      <Members />
      <Students />
    </div>
    );
}

export default Team;
