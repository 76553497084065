import React from "react";
import TeamMember from './TeamMember';
import speaker1 from '../assets/speakers/Anil Kumar Vuppala.jpg';
import speaker2 from '../assets/speakers/Sripana saha.jpg';
// import speaker3 from '../assets/speakers/M Vidya sagar.jpg';
import guest7 from '../assets/speakers/rashmi_ranjan.jpeg';
import speaker4 from '../assets/speakers/Prasanta Kumar Gosh.jpg';
import speaker5 from '../assets/speakers/Santosh Kumar Vipparthi.png';
import speaker6 from '../assets/speakers/Mukesh.jpg';
import speaker7 from '../assets/speakers/Sumantra Dutta.jpg';
import speaker8 from '../assets/speakers/Partha Pratim Roy.jpeg';
import speaker9 from "../assets/speakers/ranjitha.jpg"
import speaker10 from "../assets/speakers/Ashok_Photo_2_1.jpg"
 

function Speakers(props) {
  return (
    <section className={props.classes} id="speakers">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Speakers</h2>
          </div>
        </div>
        <div className="row my-3">
        
        <TeamMember
            src={speaker7}
            alt="one"
            title="Dr. Sumantra Dutta"
            designation={`Professor,
             IIT Delhi`}
          />
           <TeamMember
            src={speaker4}
            alt="one"
            title="Dr. Prasanta Kumar Gosh"
            designation={` Associate Professor,
             IISc Bangalore.`}
          />
            <TeamMember
            src={speaker2}
            alt="one"
            title="Dr. Sriparna Saha"
            designation={`Associate Professor, 
              IIT Patna `}
          />
           <TeamMember
            src={speaker1}
            alt="one"
            title="Dr. Anil Kumar Vuppala"

            designation={` Associate Professor,
            IIIT Hyderabad`}  
          />

        </div>
         

        <div className="row my-3">
         

          <TeamMember
            src={speaker5}
            alt="one"
            title="Dr. Santosh Kumar Vipparthi"
            designation={`Assistant Professor ,
             MNIT Jaipur.`}
          />
          
          <TeamMember
            src={speaker6}
            alt="one"
            title="Dr. Mukesh Kumar"
            designation={` Assistant Professor,
             NIT Patna`}
          />
           <TeamMember
            src={guest7}
            alt="one"
            title="Dr. Rashmi Ranjan Rout"
            designation={`Associate Professor,
            NIT Warangal`}
          />
           <TeamMember
            src={speaker8}
            alt="one"
            title="	Dr.Partha Pratim Roy"
            designation={`Associate Professor,
             IIT Roorkee. `}
          />
          </div>
          <div className="row my-3">

          <div class="col-sm-3"></div>

         
        
        <TeamMember
            src={speaker9}
            alt="one"
            title="	Dr. Ranjitha Prasad"
            designation={`Assistant Professor,
             IIIT Delhi. `}
          />
          <TeamMember
            src={speaker10}
            alt="one"
            title="Mr. Ashok Kumar Reddy Chavva"
            designation={`Staff Engineer,
              SRI-Bangalore, Samsung Electronics. `}
          />
          <div class="col-sm-2"></div>
        </div>
      </div>
    </section>
  );
}

export default Speakers;
