import React from "react";
import Navigation from "./components/Navigation";
import HomePage from "./components/HomePage";
import About from "./components/About";
import Schedule from "./components/Schedule";
import Registration from "./components/Registration";
import EventDetails from "./components/EventDetails";
import Team from "./components/Team";
import Gallery from "./components/Gallery";
import Contact from "./components/Contact";
import TravelGrant from "./components/TravelGrant";
import Footer from "./components/Footer";
import KnowledgeBase from "./components/KnowledgeBase";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import SPS2020 from "./components/SPS2020";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/about" component={About} />
        <Route exact path="/schedule" component={Schedule} />
        <Route exact path="/registration" component={Registration} />
        <Route exact path="/eventdetails" component={EventDetails} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/travelgrant" component={TravelGrant} />
        <Route exact path="/team" component={Team} />
        <Route exact path="/knowledgebase" component={KnowledgeBase} />
        <Route exact path="/SPS2020" component={SPS2020}/>
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
