import React from "react";

function About() {
  return (
    <section className="page-section" id="about">
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">About IEEE VNRVJIET</h2>
            <p className="text-muted">IEEE (Institute of Electrical & Electronic Engineers) is the worlds' leading resource for technological innovation and professional network in the fields of electrical and electronic computing. IEEE VNRVJIET Student Branch was formed on August 27, 1998 with a vision of helping students and professionals explore the benefits of IEEE and encouraging them to evolve into competent engineers by providing unwavering foundation and support in distinct domains of engineering in the form of Competitions, Guest Lectures, Webinars, Workshops, Faculty Development Programmes and much more. Since its inception, it has won numerous awards and earned recognition on Section and National levels. Currently, it comprises of 4 Student Chapters and 1 Affinity Group.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">About SPS</h2>
            <h3 className="section-subheading text-muted">
              {/* Lorem ipsum dolor sit amet consectetur. */}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <ul className="timeline">
              <li>
                <div className="timeline-image" style={schoolStyle}>
                  {/* <img
                    className="rounded-circle img-fluid"
                    src="img/about/1.jpg"
                    alt=""
                  /> */}
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    {/* <h4>2009-2011</h4> */}
                    <h4 className="subheading">Seasonal Schools</h4>
                  </div>
                  <div className="timeline-body">
                    <p className="text-muted">
                      SPS Seasonal schools are typically either summer schools or
                      winter schools, and are geared towards providing graduate
                      students and early stage researchers with background 
                      on selected topics in signal processing
                    </p>
                  </div>
                </div>
              </li>

              <li className="timeline-inverted">
                <div className="timeline-image" style={forumStyle}>
                  {/* <img
                    className="rounded-circle img-fluid"
                    src="img/about/2.jpg"
                    alt=""
                  /> */}
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    {/* <h4>March 2011</h4> */}
                    <h4 className="subheading">Forums</h4>
                  </div>
                  <div className="timeline-body">
                    <p className="text-muted">
                      Aimed at networking and with topics of interest to
                      industry members. Formats include panel sessions, and
                      presentations or lectures by industry experts. Content
                      should be created based on interest to industry, and
                      cannot include papers for publication.
                    </p>
                  </div>
                </div>
              </li>

              <li>
                <div style={aboutstyles} className="timeline-image">
                  {/* <img
                    className="rounded-circle img-fluid"
                    src="https://image.shutterstock.com/image-photo/beautiful-water-drop-on-dandelion-260nw-789676552.jpg"
                    alt="rrrr"
                    
                  /> */}
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    {/* <h4>December 2012</h4> */}
                    <h4 className="subheading">Regional Meetings</h4>
                  </div>
                  <div className="timeline-body">
                    <p className="text-muted">
                      Local high quality technical events (such as local
                      workshops or small conferences) to serve the local or
                      regional community. Events should target themes of
                      interest to SPS members. Meetings can include conference
                      paper publications.
                    </p>
                  </div>
                </div>
              </li>

              <li className="timeline-inverted">
                <div className="timeline-image" style={initiativeStyle}>
                  {/* <img
                    className="rounded-circle img-fluid"
                    src="img/about/4.jpg"
                    alt=""
                  /> */}
                </div>
                <div className="timeline-panel">
                  <div className="timeline-heading">
                    {/* <h4>July 2014</h4> */}
                    <h4 className="subheading">Chapter Initiatives</h4>
                  </div>
                  <div className="timeline-body">
                    <p className="text-muted">
                      Additional options for grant funding may include:
                      chapter- centered events for practicing engineers, chapter
                      meeting featuring industry executives, outreach to get
                      students at primary schools interested in signal
                      processing, design competitions or other ideas.
                    </p>
                  </div>
                </div>
              </li>

              <li className="timeline-inverted">
                <div className="timeline-image">
                  <h4>
                    Be a Part
                    <br />
                    Of Our
                    <br />
                    Story!
                  </h4>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

const aboutstyles = {
  backgroundImage:
    'url("https://cdn.dribbble.com/users/1563378/screenshots/3425902/charminar_2x.png")',
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center"
};

const initiativeStyle = {...aboutstyles,
  backgroundImage:
    'url("https://blog.sli.do/wp-content/uploads/2019/10/event-ideas.png")',
}

const forumStyle = {...aboutstyles,
  backgroundImage:
    'url("https://images.unsplash.com/photo-1506869640319-fe1a24fd76dc")',
}

const schoolStyle = {...aboutstyles,
  backgroundImage:
    'url("https://images.unsplash.com/photo-1496104679561-38d3af73f9b0")',
}

export default About;
