import React from "react";

function Gallery() {
  return (
    <div class="container">
      <h1 class="font-weight-light text-center text-lg-left mt-4 mb-0">
        Gallery
      </h1>

      <hr class="mt-2 mb-5" color="primary" />

      <div class="row text-center text-lg-left">
        <div class="col-lg-3 col-md-4 col-6">
          <img
            class="img-fluid img-thumbnail"
            src="https://source.unsplash.com/pWkk7iiCoDM/400x300"
            alt=""
          />
        </div>
        <div class="col-lg-3 col-md-4 col-6">
          <img
            class="img-fluid img-thumbnail"
            src="https://source.unsplash.com/aob0ukAYfuI/400x300"
            alt=""
          />
        </div>
        <div class="col-lg-3 col-md-4 col-6">
          <img
            class="img-fluid img-thumbnail"
            src="https://source.unsplash.com/EUfxH-pze7s/400x300"
            alt=""
          />
        </div>
        <div class="col-lg-3 col-md-4 col-6">
          <img
            class="img-fluid img-thumbnail"
            src="https://source.unsplash.com/M185_qYH8vg/400x300"
            alt=""
          />
        </div>
        <div class="col-lg-3 col-md-4 col-6">
          <img
            class="img-fluid img-thumbnail"
            src="https://source.unsplash.com/sesveuG_rNo/400x300"
            alt=""
          />
        </div>
        <div class="col-lg-3 col-md-4 col-6">
          <img
            class="img-fluid img-thumbnail"
            src="https://source.unsplash.com/AvhMzHwiE_0/400x300"
            alt=""
          />
        </div>
        <div class="col-lg-3 col-md-4 col-6">
          <img
            class="img-fluid img-thumbnail"
            src="https://source.unsplash.com/2gYsZUmockw/400x300"
            alt=""
          />
        </div>
        <div class="col-lg-3 col-md-4 col-6">
          <img
            class="img-fluid img-thumbnail"
            src="https://source.unsplash.com/EMSDtjVHdQ8/400x300"
            alt=""
          />
        </div>
        <div class="col-lg-3 col-md-4 col-6">
          <img
            class="img-fluid img-thumbnail"
            src="https://source.unsplash.com/8mUEy0ABdNE/400x300"
            alt=""
          />
        </div>
        <div class="col-lg-3 col-md-4 col-6">
          <img
            class="img-fluid img-thumbnail"
            src="https://source.unsplash.com/G9Rfc1qccH4/400x300"
            alt=""
          />
        </div>
        <div class="col-lg-3 col-md-4 col-6">
          <img
            class="img-fluid img-thumbnail"
            src="https://source.unsplash.com/aJeH0KcFkuc/400x300"
            alt=""
          />
        </div>
        <div class="col-lg-3 col-md-4 col-6">
          <img
            class="img-fluid img-thumbnail"
            src="https://source.unsplash.com/p2TQ-3Bh3Oo/400x300"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Gallery;
