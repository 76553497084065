import React from 'react';

export default ()=>(
    <div id="meta-nav" className="hidden-xs">
          <div className="container">
              <div className="row">
                  <div className="col-xs-12">
                      <ul id="meta">
                          <li><a href="https://www.ieee.org/" target="_blank">IEEE.org</a></li>
                          <li><a href="http://ieeexplore.ieee.org/" target="_blank">IEEE <em>Xplore</em> Digital Library</a>
                          </li><li><a href="http://standards.ieee.org/" target="_blank">IEEE Standards</a>
                          </li><li><a href="http://spectrum.ieee.org/" target="_blank">IEEE Spectrum</a>
                          </li><li><a href="https://www.ieee.org/sitemap.html" target="_blank">More Sites</a>
                      </li></ul>
                  </div>
              </div> 
          </div>
      </div>
    )