import React from 'react';
import { Table } from "reactstrap";
// import TeamMember from './TeamMember';
// import sessionsChair from '../assets/Team/B.V.Kiranmai.jpg';
// import transportChair from '../assets/Team/Dr.Padmasree.jpg';
// import generalChair from '../assets/Team/Dr.Y.Padma Sai.jpg';
// import financeChair1 from '../assets/Team/Manjulasree.jpg';
// import organizingChair from '../assets/Team/RajendraPrasad.jpg';
// import websiteChair from '../assets/Team/rameshchandra.jpg';
// import drChalapathi from '../assets/Team/Dr.Y.Chalapathi Rao.jpg';
// import mrsArchana from '../assets/Team/archana.jpg';
// import mrsKanthi from '../assets/Team/kanthi.jpg';
// import mrAnudeep from '../assets/Team/Anudeep.jpg';
// import mrSravanth from '../assets/Team/Sravanth.JPG';
// import mrsKumari from '../assets/Team/Rajakumari.jpg';



export default ()=>(
    <section className="bg-light page-section pt-2" id="team">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Organizing Committee Members</h2>
            
          </div>
        </div>
        
        <Table hover responsive>
            <tbody>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Designation</th>
            </tr>
            <tr>
                <th scope="row">1</th>
                <td>Dr. B. V. Kiranmai</td>
                <td>Professor-CSE, VNRVJIET</td>
            </tr>
            <tr>
                <th scope="row">2</th>
                <td>Dr. Y. Chalapathi Rao</td>
                <td>Associate Professor-ECE, VNRVJIET</td>
            </tr>
            <tr>
                <th scope="row">3</th>
                <td>Dr. D. Ravi Kumar</td>
                <td>Associate Professor-EEE, VNRVJIET</td>
            </tr>
            <tr>
                <th scope="row">4</th>
                <td>Mr. D. Ramesh Reddy </td>
                <td> Assistant Professor-ECE, VNRVJIET</td>
            </tr>
            <tr>
                <th scope="row">5</th>
                <td>Mr. B. B. SHABARINATH</td>
                <td>Assistant Professor-ECE, VNRVJIET</td>
            </tr>
            <tr>
                <th scope="row">6</th>
                <td>Dr. G. Radhika</td>
                <td>Assistant Professor- EEE, VNRVJIET</td>
            </tr>
            <tr>
                <th scope="row">7</th>
                <td>Dr. V. Sagar Reddy</td>
                <td>Asst Professor-ECE, VNRVJIET</td>
            </tr>
            <tr>
                <th scope="row">8</th>
                <td>Mrs. K. Archana Bhange</td>
                <td>Sr. Asst Professor-ECE, VNRVJIET</td>
            </tr>
            <tr>
                <th scope="row">9</th>
                <td>Mrs. J. L. V. Ramana Kumari</td>
                <td>Assistant Professor-ECE, VNRVJIET</td>
            </tr>
            <tr>
                <th scope="row">10</th>
                <td>Mrs. D. Kanthi Sudha</td>
                <td>Asst Professor-ECE, VNRVJIET</td>
            </tr>
            <tr>
                <th scope="row">11</th>
                <td>Mr. G. Vijaya Kumar</td>
                <td> Assistant Professor- ECE, VNRVJIET</td>
            </tr>
            <tr>
                <th scope="row">12</th>
                <td>Ms.Prativa Rani Saha</td>
                <td> Assistant Professor- H&S, VNRVJIET</td>
            </tr>
            
            <tr>
                <th scope="row">13</th>
                <td>Ms. K. Swetha</td>
                <td>Assistant Professor-ECE, VNRVJIET</td>
            </tr>
            <tr>
                <th scope="row">14</th>
                <td>Mr. Pradeep Kumar</td>
                <td>Assistant Professor- ECE, VNRVJIET</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </section>)