import React from "react";
import { Table } from "reactstrap";

function Contact() {
  return (
    <div className="container">
      <h1 className="my-4">Contact Details</h1>
      <hr className="my-4 " color="primary" />
      <Table hover responsive>
        <tbody>
          <tr>
            <th scope="row">Primary Point of Contact</th>
            <td>
              Dr. Y. Padma Sai
              <br />
              Senior Member of IEEE,
              <br />
              Professor  of ECE,
              <br />
              VNR Vignana Jyothi Institute of Engineering and Technology.
              <br />
              Email ID: padmasai_y@ieee.org
              <br />
              Contact Number: +91 9000037660
            </td>
          </tr>
          {/* <tr>
            <th scope="row">Secondary Point of Contact</th>
            <td>
              Dr. P. Kishore
              <br />
              Senior Member of IEEE,
              <br />
              Assistant Professor-ECE,
              <br />
              VNR Vignana Jyothi Institute of Engineering and Technology.
              <br />
              Email ID: kishore_p@vnrvjiet.in
              <br />
              Contact Number: +91 9866940403
            </td>
          </tr> */}
            <tr>
            <th scope="row">Secondary Point of Contact</th>
            <td>
              Dr.Lalitha Vadlamani
              <br />
              Senior Member of IEEE,
              <br />
              Assistant Professor,
              <br />
              IIIT Hyderabad              <br />
              Email ID: lalitha.v@iiit.ac.in
              <br />
            </td>
          </tr> 
          <tr>
          
            <th scope="row">For any Queries</th>
            <td>
              Email ID: vnrieeespsiot@gmail.com
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default Contact;
