import React from 'react';
import { Table } from "reactstrap";

export default ()=>(
<section className="bg-light page-section pt-2" id="team">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Student Members</h2>
            
          </div>
        </div>
        <Table hover responsive>
            <tbody>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Position</th>
            </tr>
            <tr>
                <th scope="row">1</th>
                <td>Mr. Sai Kamal Kasanagottu</td>
                <td>Chair, Treasurer, IEEE VNR SB</td>
            </tr>
            <tr>
                <th scope="row">2</th>
                <td>Ms.Sriya Challa</td>
                <td>Chair, IEEE VNR SPS SBC</td>
            </tr>
            <tr>
                <th scope="row">3</th>
                <td>Mr.Praneeth Achanta</td>
                <td>Chair, IEEE VNR CS SBC</td>
            </tr>
            <tr>
                <th scope="row">4</th>
                <td>Mr.Sathwick Ramasani</td>
                <td>Chair, IEEE VNR PES SBC</td>
            </tr>
            <tr>
                <th scope="row">5</th>
                <td>Mr.Ramakrishna Challapalli</td>
                <td>Chair, IEEE VNR CAS SBC</td>
            </tr>
            <tr>
                <th scope="row">6</th>
                <td>Ms. Pujika Upadhyay</td>
                <td>Chair, IEEE VNR WIE AG</td>
            </tr>
            <tr>
                <th scope="row">7</th>
                <td>Mr. Yashaswi Lakku</td>
                <td>Vice-Chair, IEEE VNR SB</td>
            </tr>
            <tr>
                <th scope="row">8</th>
                <td>Mr. Suhas Patlolla</td>
                <td>Vice-Chair, IEEE VNR SPS SBC</td>
            </tr>
            <tr>
                <th scope="row">9</th>
                <td>Mr.Uday Kiran Irukula</td>
                <td>Vice-Chair, IEEE VNR CS SBC</td>
            </tr>
            <tr>
                <th scope="row">10</th>
                <td>Mr.Bhanu Prakash Peddamma</td>
                <td>Vice-Chair, IEEE VNR PES SBC</td>
            </tr>
            <tr>
                <th scope="row">11</th>
                <td>Ms. Surabhi Seetha</td>
                <td>Vice-Chair, IEEE VNR WIE SBC</td>
            </tr>
            <tr>
                <th scope="row">12</th>
                <td>Mr.Rushik Sai</td>
                <td>Vice-Chair, IEEE VNR CAS SBC</td>
            </tr>
            <tr>
                <th scope="row">13</th>
                <td>Mr.Praneeth Achanta</td>
                <td>Secretary, IEEE VNR SB</td>
            </tr>
            <tr>
                <th scope="row">14</th>
                <td>Mr.Khaja Mohiuddin </td>
                <td>Secretary, IEEE VNR SPS SBC</td>
            </tr>
            <tr>
                <th scope="row">15</th>
                <td>Mr.Mohammed Abdul Subhan</td>
                <td>Secretary, IEEE VNR CS SBC</td>
            </tr>
            <tr>
                <th scope="row">16</th>
                <td>Mr.Rohit Reddy Somu</td>
                <td>Secretary, IEEE VNR PES SBC</td>
            </tr>
            <tr>
                <th scope="row">17</th>
                <td>Mr.Sai Sharan Morisetty</td>
                <td>Secretary, IEEE VNR CASS SBC</td>
            </tr>
            <tr>
                <th scope="row">18</th>
                <td>Ms. Pallavi Sudha Meloju</td>
                <td>Secretary, IEEE VNR WIE AG</td>
            </tr>
            <tr>
                <th scope="row">19</th>
                <td>Uddaraju Vidya Sivani</td>
                <td>Web Master, IEEE VNR SB</td>
            </tr>
            <tr>
                <th scope="row">20</th>
                <td>Vikas Kondoor</td>
                <td>Web Master, VNR CS SBC</td>
            </tr>
            <tr>
                <th scope="row">21</th>
                <td>Praveen </td>
                <td>Web Dev Team, IEEE VNR SB</td>
            </tr>
            </tbody>
        </Table>
        </div>
    </section>
    
  )
