import React from "react";
import TeamMember from './TeamMember';
import president from '../assets/AdvisoryCommittee/President_DNRao.jpg';
import gensec from '../assets/AdvisoryCommittee/GenSec_Harischandra.jpg';
import amitKumar from '../assets/AdvisoryCommittee/AmitKumar.jpg';
import chairLalitha from '../assets/AdvisoryCommittee/Chair_Lalitha.jpg';
import gvvSharma from '../assets/AdvisoryCommittee/GVVSharma.jpg';
import principal from '../assets/AdvisoryCommittee/Principal_CDNaidu.jpg';
import ieeeSec from '../assets/AdvisoryCommittee/SecretaryIEEE_BalaPrasad.jpg';
import treasurer from '../assets/AdvisoryCommittee/Treasurer_NookalaSrinivas.jpg';
import guest1 from '../assets/speakers/Guest1_DrRamaSubbaReddy.jpg';
import directorSandhya from '../assets/AdvisoryCommittee/SandhyaKode.jpeg';
import chairYP from '../assets/AdvisoryCommittee/ChairYP_VamsiKrishna.jpg';
import drAnuradha from '../assets/AdvisoryCommittee/DrAnuradha.jpg';
import drPoonam from '../assets/AdvisoryCommittee/DrPoonam.jpg';
import director from '../assets/AdvisoryCommittee/Director_VNR.jpg';
import sureshbabu from '../assets/Team/sureshbabu.jpg';
import jsrao from '../assets/Team/JSRaoSir.jpg';
import ramneek from '../assets/Team/ramneek.jpg';
import hod from '../assets/Team/RajendraPrasad.jpg';
import kode from '../assets/Team/kodedurgaprasad.jpg';
import vidyasagar from '../assets/Team/vidyasagar.jpg';
import venkatesh from '../assets/Team/venkatesh.jpg';
import sreenivas from '../assets/Team/sreenivas.jpg';
import subhanandarao from '../assets/Team/subhanandarao.jpg';
import chennakeshavarao from '../assets/Team/chennakeshavarao.jpg';
import cdnaidu from '../assets/Team/cdnaidu.jpg';
import anuradha from '../assets/Team/anuradha.jpg';
import poonam from '../assets/Team/poonam.jpg';
import vijayalatha from '../assets/Team/vijayalatha.jpg';
import generalChair from '../assets/Team/Dr.Y.Padma Sai.jpg';
import anudeep from '../assets/Team/Anudeep.jpg';
import naveenkumar from '../assets/Team/naveenkumar.jpg';
import rajani from '../assets/Team/rajani.jpg';
import financeChair1 from '../assets/Team/Manjulasree.jpg';
import arif from "../assets/speakers/mohammed-arifuddin-sohel.jpeg"
import bala from "../assets/speakers/r.balasubmanian.jpg"


function Committee() {
  return (
    <section className="bg-light page-section pb-0" id="committee">
    
      <div className="container">
      <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Advisory Committee</h2>
            
          </div>
        </div>
        <div className="row mt-4">
          <TeamMember 
                src={president}
                alt="one"
                title="Dr. D. N. Rao "
                designation={`Former President,
                 Vignana Jyothi,
                  Hyderabad `}
          />
          <TeamMember 
                src={gensec}
                alt="one"
                title="Sri. K. Harischandra Prasad"
                designation={`Former General Secretary,
                 Vignana Jyothi,
                  Hyderabad `}
          />
          <TeamMember 
                src={sureshbabu}
                alt="one"
                title=" Sri. D. Suresh Babu "
                designation={`President,
                 Vignana Jyothi,
                  Hyderabad`}
          />
          <TeamMember 
                src={jsrao}
                alt="one"
                title="Er. J. S. Rao"
                designation={`General Secretary,
                Vignana Jyothi,
                  Hyderabad `}
          />
        </div>

        <div className="row">
          <TeamMember 
                src={kode}
                alt="one"
                title="Sri. Kode Durga Prasad"
                designation={`Vice President,
                Vignana Jyothi,
                 Hyderabad  `}
          />
          <TeamMember 
                src={directorSandhya}
                alt="one"
                title="Dr. Sandhya Kode"
                designation={`Director,
                 EnhanceEdu,
                  IIIT Hyderabad  `}
          />
           <TeamMember 
                src={sreenivas}
                alt="one"
                title="Mr. Jasti Sreenivas "
                designation={`Chair, IEEE Hyderabad Section`}
          />
          <TeamMember 
                src={ieeeSec}
                alt="one"
                title="Mr. Bala Prasad Peddigari "
                designation={`Treasurer, IEEE Hyderabad Section`}
          />
          

      </div> 
        <div className="row">
        <TeamMember 
                src={guest1}
                alt="one"
                title="Dr. M. Rama Subba Reddy"
                designation={`Professor, IIT Chennai  `}
          />
          <TeamMember 
                src={gvvSharma}
                alt="one"
                title="Dr. G. V. V. Sharma "
                designation={`Associate Professor, IIT Hyderabad  `}
          />
        <TeamMember 
                src={bala}
                alt="R. Balasubramaniam"
                title="Dr. R. Balasubramaniam"
                designation={`Vice Chair, IEEE Hyderabad Section`}
          />
         
        <TeamMember 
                src={arif}
                alt="Arifuddin Sohel"
                title="Dr. Arifuddin Sohel"
                designation={`Secretary, IEEE Hyderabad Section`}
          />
         
        </div>
        <div className="row">
          <TeamMember 
                src={amitKumar}
                alt="Mr. Amit Kumar"
                title="Mr. Amit Kumar"
                designation={`Immediate Past Chair, IEEE Hyderabad Section`}
          />
          <TeamMember 
                src={chairLalitha}
                alt=" Dr.Lalitha Vadlamani"
                title=" Dr. Lalitha Vadlamani"
                designation={`Chair-SPS ,
                IEEE Hyderabad Section`}
          />
          <TeamMember 
                src={vidyasagar}
                alt="one"
                title="Mr. T. Vidya Sagar"
                designation={`Chair SAC,
                 IEEE Hyderabad Section`}
          />
          <TeamMember 
                src={venkatesh}
                alt="one"
                title="Mr. N. Venkatesh"
                designation={`Past Chair,
                IEEE Hyderabad Section`}
          />

</div>
        <div className="row">
       
          <TeamMember 
                src={subhanandarao}
                alt="one"
                title="Dr. A. Subhananda Rao "
                designation={`Director R&D,
                 VNRVJIET`}
          />
          <TeamMember 
                src={chennakeshavarao}
                alt=" one"
                title="Dr. B. Chennakesava Rao"
                designation={`Director for Advancement & Dean-Admin,
                 VNRVJIET`}
          />

          <TeamMember 
                src={cdnaidu}
                alt="one"
                title="Dr. C. D. Naidu"
                designation={`Principal,
                VNRVJIET`}
          />
          <TeamMember 
                src={anuradha}
                alt="one"
                title="Dr. K. Anuradha"
                designation={`Professor,
                Dean Academics,
                VNRVJIET`}
          />

        </div>
        <div className="row">
        <TeamMember 
                src={poonam}
                alt="one"
                title="Dr. Poonam Upadhyay"
                designation={`Professor & Head -EEE,
                 VNRVJIET`}
          />
          <TeamMember 
                src={ramneek}
                alt="one"
                title="Mr. K. Ramneek"
                designation={`IEEE Impact Creator,
                 Hyderabad`}
          />

          <TeamMember 
                src={vijayalatha}
                alt="one"
                title="Dr. Y. Vijaya Latha"
                designation={`Chair,
                Education Society,
                IEEE Hyderabad Section`}
          />
          <TeamMember 
                src={hod}
                alt="HOD"
                title=" Dr. S. Rajendra Prasad"
                designation={`Professor & Head-ECE,
                  VNRVJIET`}
          />

          </div>
        

        
        
        
        <div className="row">
          <div className="col-lg-8 mx-auto text-center">
            <p className="large text-muted">
              {/* Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut
              eaque, laboriosam veritatis, quos non quis ad perspiciatis, totam
              corporis ea, alias ut unde. */}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Committee;
