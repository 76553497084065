import React from "react";
import TeamMember from './TeamMember';
// import guest1 from '../assets/speakers/Guest1_DrRamaSubbaReddy.jpg';
import guest2 from '../assets/speakers/cp-ravi.jpg';
import speaker3 from '../assets/speakers/M Vidya sagar.jpg';
import guest3 from "../assets/speakers/Ratnakar.jpg"
import guest4 from "../assets/speakers/Cilia.png"
function Guests() {
  return (
    <section className="bg-light page-section pb-1 pt-4" id="guests">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Chief Guests</h2>
          </div>
        </div>
        <div className="row my-3">
            <TeamMember cols={3}
            src={speaker3}
            alt="one"
            title="Dr. M. Vidya Sagar"
            designation={`Fellow of The Royal Society, SERB National Science Chair,
             Distinguished Professor, IIT Hyderabad.`}
            />
            
            <TeamMember cols={3}
                    src={guest2}
                    alt="one"
                    title="Dr. C. P. Ravi Kumar"
                    designation={` Technical Director, 
                    Technical Talent Development at Texas Instruments, Bengaluru.`}
            />
            <TeamMember cols={3}
            src={guest3}
            alt="three"
            title="Mr. Ratnakar Ravarapu"
            designation={`Senior Director, Engineering at Samsung R&D Bangalore, India. `}
            />
            <TeamMember cols={3}
            src={guest4}
            alt="four"
            title="Dr. Celia Shehnaz"
            designation={`Professor, Dept. of EEE, BUET.
            Chair, IEEE SPS Woman in Signal Processing Committee & IEEE WIE History Subcommittee.
            IEEE  WIE Committee Chair Elect,2022 `}
            />
          </div>

      </div>
    </section>
  );
}

export default Guests;
