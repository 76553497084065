import React from "react";
import ScheduleRow from "./ScheduleRow";
import { Table } from "reactstrap";

const Schedule = (props) => {
  return (
    <div className="container">
      <div>
        <h2 className="my-4">Schedule</h2>
      </div>
      <hr className="my-4" color="primary" />
      <Table hover responsive>
        <tbody>
          <tr>
            <td></td>
            <td style={{ textAlign: "center", fontWeight: "700" }}>
              28-Dec-2021
            </td>
            <td></td>
          </tr>
          <ScheduleRow topic="Registration " time="9:00Hrs-10:00Hrs" />
          <ScheduleRow
            topic=" Inauguration from 9:30 AM to 10AM
followed by 
 Keynote Talk
 by Dr. M. Vidyasagar 
FRS, SERB-National Science Chair and Distinguished Professor, IIT, Hyderabad
m.vidyasagar@ee.iith.ac.in

            "
            time="10:00Hrs-11:30Hrs"
          />
          <ScheduleRow
            session="Session-1"
            topic="Artificial intelligence – search strategies "
            speaker="Dr.N.Sandhya
              Professor, CSE & Head AI&ML, VNRVJIET, Hyderabad.
             "
            time="11:45Hrs-13:15Hrs"
          />
          <ScheduleRow
            session="Session-2"
            topic="Machine Learning and its Applications"
            speaker="Dr. Mukesh Kumar
              Assistant Professor ,CSE, 
              NIT Patna.
              "
            time="14:00Hrs-15:30Hrs"
          />
          <ScheduleRow
            session="Session-3"
            topic="Artificial Intelligence Trends and Its Impact"
            speaker="Mr. Bala Prasad Peddigari,
              Treasurer, IEEE, Hyderabad Section, Chief Products Officer, HiTech CTO, TCS Ltd.
              "
            time="15:45Hrs-17:15Hrs"
          />

          <tr>
            <td></td>
            <td style={{ textAlign: "center", fontWeight: "700" }}>
              29-Dec-2021
            </td>
            <td></td>
          </tr>
          <ScheduleRow
            session="Session-4"
            topic="Python for Machine Learning Algorithms"
            speaker="Mr.B.B.Shabarinath, 
              Assistant Professor, ECE, VNRVJIET,  Hyderabad.
              "
            time="09:00Hrs-10:00Hrs"
          />
          <ScheduleRow
            session="Session-5"
            topic="Recent advancements of AI in the field of Computer Vision "
            speaker="Dr. Santosh Kumar Vipparthi
              Assistant Professor, CSE,
               Malaviya National Institute of Technology, Jaipur.
              "
            time="10:00Hrs-11:30Hrs"
          />
          <ScheduleRow
            session="Session-6"
            topic="Artificial Intelligence in Speech Processing Applications "
            speaker="Dr. Anil Kumar Vuppala Associate Professor, ECE, IIIT, Hyderabad."
            time="11:45Hrs-13:15Hrs"
          />
          <ScheduleRow
            session="Session-7"
            topic="Introduction of EEG Signals and Its Applications using Machine Learning
"
            speaker="Dr.Partha Pratim Roy
              Associate Professor, CSE,
              IIT Roorkee.
              "
            time="14:00Hrs-15:30Hrs"
          />
          <ScheduleRow
            session="Session-8"
            topic="Principles of Navigation Systems: Toy Car assembly, Control and Communication"
            speaker="Dr.G.V.V Sharma
              Associate professor, IIT Hyderabad.
              "
            time="15:45Hrs-17:15Hrs"
          />
          <tr>
            <td></td>
            <td style={{ textAlign: "center", fontWeight: "700" }}>
              30-Dec-2021
            </td>
            <td></td>
          </tr>

          <ScheduleRow
            session="Session-9"
            topic="Python for Machine Learning Algorithms"
            speaker="Mr.B.B.Shabarinath, 
              Assistant Professor, ECE, VNRVJIET,  Hyderabad.
              "
            time="09:00Hrs-10:00Hrs"
          />
          <ScheduleRow
            session="Session-10"
            topic="Bayesian Techniques for Local post-hoc XAI
"
            speaker="Dr. Ranjitha Prasad
Assistant Professor, 
ECE,IIIT Delhi
 
              "
            time="10:00Hrs-11:30Hrs"
          />
          <ScheduleRow
            session="Session-11"
            topic="Machine Learning for Wireless Sensor Networks"
            speaker="Dr. Rashmi Ranjan Rout,
              Associate Professor, CSE,
              NIT Warangal.
              "
            time="11:45Hrs-13:15Hrs"
          />
          <ScheduleRow
            session="Session-12"
            topic="Medical Informatics and Biometrics with Machine Learning and Deep Learning"
            speaker="Dr. Sumantra Dutta 
              Professor, EE,  IIT Delhi.
              "
            time="14:00Hrs-15:30Hrs"
          />
          <ScheduleRow
            session="Session-13"
            topic="Signal processing and artificial intelligence for dysarthric speech"
            speaker="Dr.Prasanta Kumar Ghosh Associate Professor, EE,  IISc, Bengaluru."
            time="15:45Hrs-17:15Hrs"
          />
          <tr>
            <td></td>
            <td style={{ textAlign: "center", fontWeight: "700" }}>
              31-Dec-2021
            </td>
            <td></td>
          </tr>

          <ScheduleRow
            session="Session-14"
            topic="Dialogue Systems: Applications of Deep Learning-based Techniques"
            speaker="Dr. Sriparna Saha
Associate Professor, CSE,
 IIT  Patna 
 "
            time="09:00Hrs-10:00Hrs"
          />
          <ScheduleRow
            session="Session-15"
            topic="Machine learning forWireless commmunication Systems-The Beginning "
            speaker="Mr.Ashok Kumar Reddy.C 
              Architect at Samsung R&D,Bangalore,India.
              
              "
            time="10:00Hrs-11:30Hrs"
          />
          <ScheduleRow
            session="Session-16"
            topic="Artificial Intelligence and Neuroscience: Past, Present and Future"
            speaker="Mr.P.Anudeep, Assistant Professor, ECE, VNRVJIET, Hyderabad.
              
              "
            time="11:45Hrs-13:15Hrs"
          />

          <ScheduleRow
            session="Session-17"
            topic="A panel discussion on Developments and Challenges  in AI for signal processing during last decade"
            time="14:00Hrs-15:30Hrs"
          />
          <ScheduleRow
            topic="Online Quiz followed by
              Valedictory Function
              "
            time="15:45Hrs-17:15Hrs"
          />
        </tbody>
      </Table>
      <div>
        <h4 className="my-4 font-weight-bold">Other Highlights</h4>

        <li>
          <strong>
            Poster Presentations on Artificial Intelligence Applications on
            Signal Processing from 6.30 PM to 8.30PM on 28th December 2021
          </strong>
          <ul>
            <li>
              <strong>Chairs</strong>
            </li>
            <li>Dr.P.Kishore, Associate Professor, ECE, VNRVJIET</li>
            <li>Dr.L.V.Rajani Kumari, Assistant Professor, ECE, VNRVJIET</li>
            <li>Mr.V.Naveen Kumar, Assistant Professor, ECE, VNRVJIET</li>
            <li>Mr.K.Rama Mohana Reddy, Assistant Professor, ECE, VNRVJIET</li>
            <div></div>
          </ul>
        </li>
        <div></div>

        <strong>
          1. Panel Discussion on Increasing Women in STEM: Curriculum, Culture
          and Careers from 6.30 PM to 8.00 PM on 29th Dec.2021.
        </strong>
        <div className="my-3">
          <h4 className="my-4 font-weight-bold">Panellists</h4>
          <ol>
            <li>
              <div>
                <strong>Dr.Supavadee Aramvith</strong>
              </div>
              <div>
                Associate Head, Internationalization & Head, Multimedia Data
                Analytics and Processing Research Unit.
              </div>
              <div>
                Associate Professor in Electrical Engineering, Chulalongkorn
                University,Thailand.
              </div>
            </li>
            <li>
              <div>
                <strong>Dr.C.Kiran Mai</strong>
              </div>
              <div>Former Principal, Professor, CSE,VNR VJIET, Hyderabad</div>
            </li>
            <li>
              <div>
                <strong>Ms. Nita K Patel</strong>
              </div>
              <div>Director Elect IEEE R10</div>
            </li>
            <li>
              <div>
                <strong>Ms.Emi YANO</strong>
              </div>
              <div>Chair, IEEE Region 10 Women in Engineering (WIE)</div>
            </li>
            <li>
              <div>
                <strong>Mr.N Venkatesh</strong>{" "}
              </div>
              <div>
                Past Chair,IEEE Hyderabad Section, Sr. Director, Engineering
                ,Silicon Labs, Hyderabad{" "}
              </div>
            </li>
          </ol>
          <div>
            Moderator - <strong>Dr. Ramalatha Marimuthu</strong>
          </div>
          <div className="ml-5">
            Secretary, IEEE Computer Society 2021, Board of Governors, IEEE CS
            2019 – 2021, EAB Representative to IEEE Conferences Committee
            2020-2021
          </div>
        </div>

        <strong>
          2. Panel Discussion on Developments and Challenges in AI for Signal
          Processing during last decade from 2.00 PM to 3.30 PM on 31st Dec.2021
        </strong>
        <div className="my-3">
          <h4 className="my-4 font-weight-bold">Panellists</h4>
          <ol>
            <li>
              <div>
                <strong>Mr. Ratnakar Rao</strong>
              </div>
              <div>Senior Director, Samsung R&D Institute, Bengaluru.</div>
            </li>
            <li>
              <div>
                <strong>Dr.C.P.Ravikumar</strong>
              </div>
              <div>
                Technical Director, Technical Talent Development at Texas
                Instruments, Bengaluru.
              </div>
            </li>
            <li>
              <div>
                <strong>Dr.Ramjee Pallela</strong>
              </div>
              <div>COO at AIC-CCMB, Hyderabad</div>
            </li>
            <li>
              <div>
                <strong>Dr. Celia Shehnaz </strong>{" "}
              </div>
              <div>Professor, Dept. of EEE, BUET</div>
              <div>
                Chair, IEEE SPS Woman in Signal Processing Committee & IEEE WIE
                History Subcommittee.
              </div>
              <div>IEEE WIE Committee Chair Elect,2022.</div>
            </li>
          </ol>
          <div>
            Moderator - <strong>Dr. Lalitha Vadlamani</strong>
          </div>
          <div className="ml-5">Chair-SPS , IEEE Hyderabad Section</div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
