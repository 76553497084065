import React from "react";


function Registration() {
  return (
    <div className="container">
      {/* <div>
        <h2 className="my-4">Registration Details</h2>
        <hr className="my-4" color="primary"/>
        <h4>Non IEEE Student Member : ₹ 200 </h4>
        <br />
        <h4> IEEE Student Member : Free</h4>
        <br />
        <h4>Non IEEE Faculty/ Industry personals: ₹ 1000</h4> <br />
        <h4>IEEE Faculty/ Industry personals : ₹ 500</h4><br />
        <h4>IEEE SPS Professionals : Free</h4>
      </div> */}
      <iframe src="https://www.townscript.com/widget/ieee-sps-summer-school-on-internet-of-things-for-biomedical-and-healthcare-applications-234422" frameborder="0" height="1000" width="100%"></iframe>
    </div>
  );
}

const formStyles = {
  btnGoogle: {
    color: "white",
    backgroundColor: "#ea4335"
  },

  btnFacebook: {
    color: "white",
    backgroundColor: "#3b5998"
  }
};

export default Registration;
