import React from "react";
import TeamMember from "./TeamMember";
import KiranMai from "../assets/panellists/C Kiran mai.jpg";
import Emi from "../assets/panellists/Emi Yano.jpg";
import LalVad from "../assets/panellists/Lalitha Vadlamani.jpg";
import NP from "../assets/panellists/Nita Ptel.gif";
import rama from "../assets/panellists/Ramalatha.jpg";
import ramji from "../assets/panellists/Ramjee Pallela.jpg";
import supAra from "../assets/panellists/supavadee Aramvith.jpg";

function Panellists() {
  return (
    <section className="bg-light page-section pb-1 pt-2" id="guests">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Panellists</h2>
          </div>
        </div>
        <div className="row my-3">
          <TeamMember
            cols={4}
            src={KiranMai}
            alt="one"
            title="Dr.C.Kiran Mai "
            designation={`Former Principal, Professor, CSE,VNR VJIET, Hyderabad`}
          />
          <TeamMember
            cols={4}
            src={Emi}
            alt="one"
            title="Ms. Emi Yano"
            designation={`Chair, IEEE Region 10 Women in Engineering (WIE).`}
          />
          <TeamMember
            cols={4}
            src={NP}
            alt="one"
            title="Ms. Nita K Patel"
            designation={`Senior Director, Engineering Lead Design Center-Farmington ,IEEE Foundation director`}
          />
        </div>
        <div className="row my-3">
          <TeamMember
            cols={3}
            src={rama}
            alt="one"
            title="Dr.Ramalatha"
            designation={`Secretary, IEEE Computer Society 2021, Board of Governors, IEEE CS 2019 – 2021, EAB Representative to IEEE Conferences Committee 2020-2021`}
          />
          <TeamMember
            cols={3}
            src={LalVad}
            alt="one"
            title="Dr. Lalitha Vadlamani"
            designation={`Chair-SPS , IEEE Hyderabad Section`}
          />
          <TeamMember
            cols={3}
            src={ramji}
            alt="one"
            title="Dr.Ramjee Pallela"
            designation={`COO at AIC-CCMB, Hyderabad.`}
          />
          <TeamMember
            cols={3}
            src={supAra}
            alt="one"
            title="Dr. Supavadee Aramvith"
            designation={`Head, Multimedia Data Analytics and Processing Research Unit.
                    Associate Professor in Electrical Engineering, Chulalongkorn University,Thailand.`}
          />
        </div>
      </div>
    </section>
  );
}

export default Panellists;
