import React from "react";
import TeamMember from "./TeamMember";
import generalChair from "../assets/Team/Dr.Y.Padma Sai.jpg";
import mediaChair from "../assets/Team/DrCKiran.jpg";
import financeChair1 from "../assets/Team/Manjulasree.jpg";
import financeChair2 from "../assets/Team/Priyanka.jpg";
import kks from "../assets/Team/kks.jpg";
import anudeep from "../assets/Team/Anudeep.jpg";
import naveenkumar from "../assets/Team/naveenkumar.jpg";
import rajani from "../assets/Team/rajani.jpg";
import rammohan from "../assets/Team/rammohan.jpg";
import Kishore from "../assets/Team/DrKishore.jpg";
import kiranmai from "../assets/Team/c_kiran_mai.jpeg";
import kanchana from "../assets/Team/kanchana.jpg";

export default () => (
  <section className="bg-light page-section pt-2" id="team">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading text-uppercase">Program Committee</h2>
        </div>
      </div>
      <div className="row mt-4">
        <TeamMember
          src={generalChair}
          alt="one"
          title="Dr. Y. Padma Shayi"
          designation={`Professor, ECE & Dean Students Progression , VNRVJIET.
                 Chair , WIE AG, IEEE Hyderabad General Chair , Program Committee
                `}
        />
        <TeamMember
          src={rajani}
          alt="one"
          title="Dr. L. V. Rajani Kumari"
          designation={`Chair 
                Assistant Professor-ECE, VNRVJIET `}
        />
        <TeamMember
          src={anudeep}
          alt="one"
          title="Anudeep Peddi"
          designation={`Member 
                Assistant Professor- ECE, VNRVJIET `}
        />
        <TeamMember
          src={financeChair2}
          alt="one"
          title="	Dr. V. Priyanka"
          designation={`  Finance Committee Chair
            Assistant Professor-ECE, VNRVJIET`}
        />
      </div>

      <div className="row mt-4">
        {/* <div class="col-sm-3"></div> */}
        <TeamMember
          src={financeChair1}
          alt="one"
          title="Dr. R. Manjula Sri"
          designation={`Finance Committee Chair
                Professor & Head-EIE, VNRVJIET`}
        />
        <TeamMember
          src={kiranmai}
          alt="one"
          title="Dr. C. Kiran mai"
          designation={` Member 
                Professor -CSE, VNRVJIET`}
        />
        <TeamMember
          src={mediaChair}
          alt="one"
          title="Dr. C Kiran"
          designation={` Designing, Media and Publicity Chair
                 Associate Professor- EIE, VNRVJIET`}
        />
        <TeamMember
          src={rammohan}
          alt="one"
          title="Mr. K. Rama Mohana Reddy"
          designation={` Member 
                Assistant Professor- ECE, VNRVJIET `}
        />
      </div>

      <div className="row">
        <TeamMember
          src={naveenkumar}
          alt="one"
          title="V. Naveen Kumar"
          designation={` Member 
                Assistant Professor- ECE, VNRVJIET `}
        />

        <TeamMember
          src={Kishore}
          alt="one"
          title="Dr. P. Kishore"
          designation={` Member 
                Associate Professor- ECE, VNRVJIET`}
        />

        <TeamMember
          src={kks}
          alt="one"
          title="Dr. Kalyana Srinivas"
          designation={`Member 
                Assistant Professor- ECE, VNRVJIET`}
        />
        <TeamMember
          src={kanchana}
          alt="one"
          title="Dr. S.Kanchana"
          designation={`Member 
                Associate Professor- H&S, VNRVJIET`}
        />
      </div>
    </div>
  </section>
);
